<template>
  <v-layout justify-center>
    <v-flex sm8 md6 lg3>
      <v-snackbar v-model="snackbarAuth" :color="color">
        {{ text }}
        <v-btn text @click="snackbarAuth = false">
          Close
        </v-btn>
      </v-snackbar>
      <v-card class="elevation-12" height="300px">
        <v-card-title><v-flex class="text-xs-center"> <h3 class="headline mb-0" color="primary">Forgot Password</h3></v-flex></v-card-title>
        <v-form ref="form" lazy-validation>
          <v-card-text class="text-xs-center">
            <v-layout>
              <v-flex>
                <v-text-field prepend-icon="mdi-google-circles-extended" label="Domain" type="text" v-model="domain" :rules="$_requiredValidation" hide-details></v-text-field><br>
                <v-text-field prepend-icon="mdi-email" label="Email" type="text" v-model="email" :rules="email ? $_emailValidation : $_requiredValidation"></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-flex class="text-center">
              <v-btn class="ma-1" color="primary" :loading="loading" :disabled="loading" @click="sendLink">Send Request</v-btn>
              <v-btn class="ma-1" color="error" to="/">Back to Login</v-btn>
            </v-flex>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialog" width="400px">
      <v-card>
        <v-card-title>
          Message
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
         <span class="font-weight-medium">Password reset link is sent to your registered email. Please check your email</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="primary" @click="$router.push('/login')">Ok</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      email: '',
      domain: '',
      snackbarAuth: false,
      text: 'text',
      color: 'success',
      dialog: false
    }
  },
  created () {
    this.$i18n.locale = 'no'
    this.$store.dispatch('logout')
  },
  methods: {
    sendLink () {
      if (this.$refs.form.validate()) {
        this.$api.execute('post', `users/forgot_password?email=${this.email}&domain=${this.domain}&fromPwa=true`)
          .then((response) => {
            if (response) {
              this.dialog = true
            }
          }).catch((error) => {
            if (error.response.status === 404) {
              this.color = 'error'
              this.text = 'Mail not found'
              this.snackbarAuth = true
            }
          })
      }
    }
  }
}
</script>
<style>
.carousel {
  height: 200px !important;
}
</style>
